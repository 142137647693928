import axios from 'axios';
import Company from '../Model/Company';

const API_BASE_URL = process.env.REACT_APP_API_URL + '/api/GetCurrentCompanies'; // Adjust as needed
export interface SearchParams {
  query: string;
  sector:  string | number | undefined;
  regio: string | number | undefined;
  page: number;
  pageSize: number;
}

export interface SearchResult {
  totalCount: number;
  results: Company[];
}

export const fetchCompanies = async (params: SearchParams): Promise<SearchResult> => {
  const response = await axios.post<SearchResult>(API_BASE_URL, params);
  return response.data;
};

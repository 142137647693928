import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { Collapse, Input, Pagination, Select, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { fetchCompanies } from './Api/companyService'; // Adjust the import path as needed
import { Company } from './Model/Company'; // Ensure CompanyDetails and VacancyDetails are imported
import { RegionKey, Regions } from './Model/Regions';
import { SectorKey, Sectors } from './Model/Sectors';
import { loginRequest } from './Auth/authConfig';

const { Option } = Select;
const { Panel } = Collapse;

const App: React.FC = () => {
  const [data, setData] = useState<Company[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [query, setQuery] = useState<string>('');
  const [sectorKey, setSectorKey] = useState<SectorKey | undefined>(undefined); // Store sector key
  const [regio, setRegio] = useState<RegionKey | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(50);

  const { instance } = useMsal();

  const handleLogin = () => {
    instance.loginPopup(loginRequest).catch(e => {
      console.error(e);
    });
  };

  const handleLogout = () => {
    instance.logoutPopup().catch(e => {
      console.error(e);
    });
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [query, sectorKey, regio]);

  useEffect(() => {
    const loadData = async () => {
      try {
        const searchResult = await fetchCompanies({
          query,
          sector: sectorKey,
          regio: regio,
          page: currentPage,
          pageSize: pageSize
        });
        // Transform the API response if necessary
        const transformedData = searchResult.results.map(company => ({
          ...company,
          stagesDetails: company.stagesDetails,
          leerbanenDetails: company.leerbanenDetails,
          companyDetails: company.companyDetails,
        }));
        setData(transformedData);
        setTotalCount(searchResult.totalCount); // Update the total count
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    loadData();
  }, [query, sectorKey, regio, currentPage, pageSize]);

  const columns = [
    {
      title: 'Logo',
      dataIndex: 'logo',
      key: 'logo',
      render: (text: any, record: Company) => (
        record.companyDetails?.logo ? (
          <img src={record.companyDetails.logo} alt="Company Logo" style={{ width: 100 }} />
        ) : (
          'No Logo'
        )
      ),
    },
    { title: 'ID', dataIndex: 'leerbedrijfId', key: 'leerbedrijfId' },
    { title: 'Name', dataIndex: 'name', key: 'name' },
    {
      title: 'KVK Number',
      dataIndex: 'companyDetails',
      key: 'kvkNummer',
      render: (text: any, record: Company) => record.companyDetails?.kvkNummer || 'N/A'
    },
    { title: 'Sector', dataIndex: 'sector', key: 'sector' },
    { title: 'Region', dataIndex: 'regio', key: 'regio' }
  ];
  // Function to remove the part before the first '-'
  const formatTitle = (title: string) => {
    const parts = title.split(' - ');
    return parts.length > 1 ? parts.slice(1).join(' - ') : title;
  };

  const expandable = {
    expandedRowRender: (record: Company) => (
      <Collapse>
        <Panel header="Company Details" key="1">
          <div>
            <p><strong>KVK Name:</strong> {record.companyDetails?.kvkNaam || 'N/A'}</p>
            <p><strong>KVK Number:</strong> {record.companyDetails?.kvkNummer || 'N/A'}</p>
            <p><strong>KVK Vestigingsnummer:</strong> {record.companyDetails?.kvkVestigingsnummer || 'N/A'}</p>
            <p><strong>Business Type:</strong> {record.companyDetails?.bedrijfsindeling || 'N/A'}</p>
            <p><strong>Phone:</strong> {record.companyDetails?.telefoon || 'N/A'}</p>
            <p><strong>Company Size:</strong> {record.companyDetails?.bedrijfsgrootte || 'N/A'}</p>
            <p><strong>Total Capacity:</strong> {record.companyDetails?.totaleCapaciteit || 'N/A'}</p>
            <p><strong>Stage BBL Agreements:</strong> {record.companyDetails?.stageBblOvereenkomsten || 'N/A'}</p>
            <p><strong>Address:</strong> {record.companyDetails?.address || 'N/A'}</p>
            <p><strong>Postal Code/City:</strong> {record.companyDetails?.postalCodeCity || 'N/A'}</p>
            <p><strong>Country:</strong> {record.companyDetails?.country || 'N/A'}</p>
            <p><strong>Telephone Number:</strong> {record.companyDetails?.telephoneNumber || 'N/A'}</p>
            <p><strong>Email:</strong> {record.companyDetails?.email || 'N/A'}</p>
            <p><strong>Website:</strong> <a href={record.companyDetails?.website ?? ''} target="_blank" rel="noopener noreferrer">{record.companyDetails?.website || 'N/A'}</a></p>
            <p><strong>Stage Market Link:</strong> <a href={record.companyDetails?.stagemarktLink} target="_blank" rel="noopener noreferrer">{record.companyDetails?.stagemarktLink || 'N/A'}</a></p>
            {record.companyDetails?.contactpersons && record.companyDetails.contactpersons.length > 0 && (
              <div>
                <p><strong>Contact Persons:</strong></p>
                <ul>
                  {record.companyDetails.contactpersons.map((contact, index) => (
                    <li key={index}>
                      <p><strong>Name:</strong> {contact.naam || 'N/A'}</p>
                      <p><strong>Email:</strong> {contact.email || 'N/A'}</p>
                      <p><strong>Phone:</strong> {contact.tel || 'N/A'}</p>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </Panel>
        <Panel header="Stages" key="2">
          {record.stagesDetails.flat().length > 0 ? (
            <Table
              dataSource={record.stagesDetails.flat()}
              columns={[
                { title: 'Image', dataIndex: 'image', key: 'image', render: (image: string | undefined) => image ? <img src={image} alt="Vacancy" style={{ width: 100 }} /> : '' },
                { title: 'Title', dataIndex: 'title', key: 'title', render: (title: string) => formatTitle(title) },
                { title: 'Place', dataIndex: 'plaats', key: 'plaats' },
                { title: 'Start Date', dataIndex: 'startdatum', key: 'startdatum', render: (date: Date | undefined) => date ? new Date(date).toLocaleDateString() : 'N/A' },
                { title: 'Degree Program', dataIndex: 'degreeProgram', key: 'degreeProgram', render: (degreeProgram: string | undefined) => degreeProgram || 'N/A' },
                { title: 'CREBO', dataIndex: 'crebo', key: 'crebo', render: (crebo: string | undefined) => crebo || 'N/A' },
                { title: 'Niveau', dataIndex: 'niveau', key: 'niveau', render: (niveau: string | undefined) => niveau || ' ' },
                { title: 'Leerweg', dataIndex: 'leerweg', key: 'leerweg', render: (leerweg: string | undefined) => leerweg || ' ' },
                { title: 'URL', dataIndex: 'url', key: 'url', render: (url: string) => <a href={url} target="_blank" rel="noopener noreferrer">View Vacancy</a> },
              ]}
              pagination={false}
              rowKey="id"
              expandable={{
                expandedRowRender: (record) => (
                  <>
                    <SkillsPanel
                      skills={record.skills || []}
                      nonRequiredSkills={record.nonRequiredSkills || []}
                    />
                    <ExpandableContent
                      compensation={record.compensation || []}
                      requirements={record.requirements}
                      offer={record.offer}
                    />
                  </>
                ),
                rowExpandable: (record) => true, // Ensures all rows are expandable
              }}
            />
          ) : (
            <p>No vacancies available</p>
          )}
        </Panel>
        <Panel header="Leerbanen" key="3">
          {record.leerbanenDetails.flat().length > 0 ? (
            <Table
              dataSource={record.leerbanenDetails.flat()}
              columns={[
                { title: 'Image', dataIndex: 'image', key: 'image', render: (image: string | undefined) => image ? <img src={image} alt="Vacancy" style={{ width: 100 }} /> : '' },
                { title: 'Title', dataIndex: 'title', key: 'title', render: (title: string) => formatTitle(title) },
                { title: 'Place', dataIndex: 'plaats', key: 'plaats' },
                { title: 'Start Date', dataIndex: 'startdatum', key: 'startdatum', render: (date: Date | undefined) => date ? new Date(date).toLocaleDateString() : 'N/A' },
                { title: 'Degree Program', dataIndex: 'degreeProgram', key: 'degreeProgram', render: (degreeProgram: string | undefined) => degreeProgram || 'N/A' },
                { title: 'CREBO', dataIndex: 'crebo', key: 'crebo', render: (crebo: string | undefined) => crebo || '' },
                { title: 'Niveau', dataIndex: 'niveau', key: 'niveau', render: (niveau: string | undefined) => niveau || ' ' },
                { title: 'Leerweg', dataIndex: 'leerweg', key: 'leerweg', render: (leerweg: string | undefined) => leerweg || ' ' },
                { title: 'URL', dataIndex: 'url', key: 'url', render: (url: string) => <a href={url} target="_blank" rel="noopener noreferrer">View vacancy</a> },
              ]}
              pagination={false}
              rowKey="id"
              expandable={{
                expandedRowRender: (record) => (
                  <>
                    <SkillsPanel
                      skills={record.skills || []}
                      nonRequiredSkills={record.nonRequiredSkills || []}
                    />
                    <ExpandableContent
                      compensation={record.compensation || []}
                      requirements={record.requirements}
                      offer={record.offer}
                    />
                  </>
                ),
                rowExpandable: (record) => true, // Ensures all rows are expandable
              }}
            />
          ) : (
            <p>No vacancies available</p>
          )}
        </Panel>
      </Collapse>
    ),
    rowExpandable: (record: Company) => !!record.companyDetails, // Adjust as needed
  };

  const SkillsPanel = ({ skills, nonRequiredSkills }: { skills: string[], nonRequiredSkills: string[] }) => (
    <Collapse>
      <Panel header="Skills" key="1">
        {skills.length > 0 ? (
          <ul>
            {skills.map((skill, index) => (
              <li key={index}>{skill}</li>
            ))}
          </ul>
        ) : (
          <p>No skills listed</p>
        )}
      </Panel>
      <Panel header="Non-Required Skills" key="2">
        {nonRequiredSkills.length > 0 ? (
          <ul>
            {nonRequiredSkills.map((nonRequiredSkill, index) => (
              <li key={index}>{nonRequiredSkill}</li>
            ))}
          </ul>
        ) : (
          <p>No non-required skills listed</p>
        )}
      </Panel>
    </Collapse>
  );

  const ExpandableContent = ({
    compensation,
    requirements,
    offer,
  }: {
    compensation: string[],
    requirements: string | undefined,
    offer: string | undefined
  }) => (
    <Collapse>
      <Panel header="Compensation" key="1">
        {compensation.length > 0 ? (
          <ul>
            {compensation.map((comp, index) => (
              <li key={index}>{comp}</li>
            ))}
          </ul>
        ) : (
          <p>No compensation details available</p>
        )}
      </Panel>
      <Panel header="Requirements" key="2">
        {requirements ? (
          <p>{requirements}</p>
        ) : (
          <p>No requirements listed</p>
        )}
      </Panel>
      <Panel header="Offer" key="3">
        {offer ? (
          <p>{offer}</p>
        ) : (
          <p>No offer details available</p>
        )}
      </Panel>
    </Collapse>
  );

  return (
      <><AuthenticatedTemplate>
      <div>
      <button onClick={handleLogout}>Logout</button>
        <h1>Company Search</h1>
        <Input
          placeholder="Search..."
          value={query}
          onChange={(e) => {
            setQuery(e.target.value);
            setCurrentPage(1); // Reset the current page to 1 when the query changes
          } }
          style={{ marginBottom: 16 }} />
        <Select
          placeholder="Select Sector"
          value={sectorKey}
          onChange={value => setSectorKey(value as SectorKey)}
          style={{ width: 200, marginBottom: 16, marginRight: 16 }}
        >
          <Option value="">All Sectors</Option>
          {Object.entries(Sectors).map(([key, value]) => (
            <Option key={value} value={value}>
              {key}
            </Option>
          ))}
        </Select>
        <Select
          placeholder="Select Region"
          value={regio}
          onChange={value => setRegio(value as RegionKey)}
          style={{ width: 200, marginBottom: 16 }}
        >
          <Option value="">All Regions</Option>
          {Object.keys(Regions).map(key => (
            <Option key={key} value={key as RegionKey}>
              {key}
            </Option>
          ))}
        </Select>
        <h4> Found {totalCount} companies</h4>
        {/* Container for top pagination */}
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 16 }}>
          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={totalCount}
            onChange={(page, pageSize) => {
              setCurrentPage(page);
              setPageSize(pageSize);
            } } />
        </div>

        <Table
          columns={columns}
          expandable={expandable}
          dataSource={data}
          pagination={false}
          rowKey="leerbedrijfId" />

        {/* Pagination at the bottom */}
        <Pagination
          current={currentPage}
          pageSize={pageSize}
          total={totalCount}
          onChange={(page, pageSize) => {
            setCurrentPage(page);
            setPageSize(pageSize);
          } }
          style={{ marginTop: 16 }} />
      </div>
    </AuthenticatedTemplate><UnauthenticatedTemplate>
        <div>
          <p>You need to sign in to access this application.</p>
          <button onClick={handleLogin}>Sign in</button>
        </div>
      </UnauthenticatedTemplate></>
  );
};

export default App;

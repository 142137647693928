// sectors.ts

// Define the type for sector keys
export type SectorKey =  keyof typeof Sectors;

// Define the type for the Sectors object
export type SectorsType = typeof Sectors;
export const Sectors: { [key: string]: string } = {
  "Handel, Model & Ondernemerschap": "handelModeOndernemerschap",
  "Voedsel, Natuur & Leefomgeving": "voedselNatuurLeefomgeving",
  "Horeca & Bakkers": "horecaBakkerij",
  "Techniek & Procesindustrie": "techniekProcesindustrie",
  "Zorg & Welzijn": "zorgWelzijn",
  "Economie & Administratie": "economieAdministratie",
  "Instap-opleidingen": "instapOpleidingAlleSectoren",
  "Uiterlijke verzorging": "uiterlijkeVerzorging",
  "Mobiliteit & Voertuigen": "mobiliteitVoertuigen",
  "Bouw & Infra": "bouwInfra",
  "Media vormgeving": "mediaVormgeving",
  "Transport, Scheepsvaart & Logistiek": "transportScheepvaartLogistiek",
  "Afbouw, Hout & Onderhoud": "afbouwHoutOnderhoud",
  "Toerisme & Recreatie": "toerismeRecreatie",
  "Sport": "sport",
  "ICT": "ict",
  "Ambacht, Laboratorium & Gezondsheidstechniek": "ambachtLaboratoriumGezondheidstechniek",
  "CrossOver": "crossOver",
  "VMBO Leerwerktrajecten & Praktijkonderwijs": "vmboLeerwerktrajectPraktijkonderwijsVso",
  "Veiligheid": "veiligheid"
};

export const msalConfig = {
    auth: {
      clientId: "e6b1740d-5c22-438c-ac35-6dcc59111ad9", // Replace with your Azure AD client ID
      authority: "https://login.microsoftonline.com/1375cedd-90a3-4409-bb58-b00e5ef40b24", // Replace with your tenant ID
      redirectUri: "https://companiesdb.nxus.nl/", // Replace with your app URL
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: true, // Set to true if you are having issues on IE11 or Edge
    },
  };
  
  export const loginRequest = {
    scopes: ["User.Read"],
  };
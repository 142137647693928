// Define the type for sector keys
export type RegionKey = keyof typeof Regions;

// Define the type for the Sectors object
export type RegionsType = typeof Regions;
export const Regions: { [key: string]: string }= {
  "Achterhoek": "c5c506ce-03ca-e211-a270-001372415b01",
  "Amersfoort": "dbc506ce-03ca-e211-a270-001372415b01",
  "Drenthe": "c7c506ce-03ca-e211-a270-001372415b01",
  "Drechtsteden": "c6c506ce-03ca-e211-a270-001372415b01",
  "Food Valley": "c9c506ce-03ca-e211-a270-001372415b01",
  "Friesland": "cac506ce-03ca-e211-a270-001372415b01",
  "Gorinchem": "ccc506ce-03ca-e211-a270-001372415b01",
  "Groot Amsterdam": "cec506ce-03ca-e211-a270-001372415b01",
  "Gooi en Vechtstreek": "cbc506ce-03ca-e211-a270-001372415b01",
  "Groningen": "cdc506ce-03ca-e211-a270-001372415b01",
  "Haaglanden": "cfc506ce-03ca-e211-a270-001372415b01",
  "Holland Rijnland": "d1c506ce-03ca-e211-a270-001372415b01",
  "Helmond-De Peel": "d0c506ce-03ca-e211-a270-001372415b01",
  "Midden-Brabant": "d3c506ce-03ca-e211-a270-001372415b01",
  "Midden-Gelderland": "d4c506ce-03ca-e211-a270-001372415b01",
  "Midden-Holland": "d5c506ce-03ca-e211-a270-001372415b01",
  "Midden-Limburg": "d6c506ce-03ca-e211-a270-001372415b01",
  "Midden-Utrecht": "d7c506ce-03ca-e211-a270-001372415b01",
  "Noord-Holland Noord": "d8c506ce-03ca-e211-a270-001372415b01",
  "Noord-Limburg": "d9c506ce-03ca-e211-a270-001372415b01",
  "Noordoost-Brabant": "dac506ce-03ca-e211-a270-001372415b01",
  "Rijnmond": "dcc506ce-03ca-e211-a270-001372415b01",
  "Rivierenland": "ddc506ce-03ca-e211-a270-001372415b01",
  "Regio Zwolle": "d2c506ce-03ca-e211-a270-001372415b01",
  "Rijk van Nijmegen": "e3c506ce-03ca-e211-a270-001372415b01",
  "Stedendriehoek en Noordwest Veluwe": "dec506ce-03ca-e211-a270-001372415b01",
  "Twente": "dfc506ce-03ca-e211-a270-001372415b01",
  "Zaanstreek/Waterland": "e1c506ce-03ca-e211-a270-001372415b01",
  "Zuid-Holland Centraal": "e4c506ce-03ca-e211-a270-001372415b01",
  "Zuid-Kennemerland en Ijmond": "e5c506ce-03ca-e211-a270-001372415b01",
  "Zuid-Limburg": "e6c506ce-03ca-e211-a270-001372415b01",
  "Zuidoost-Brabant": "e7c506ce-03ca-e211-a270-001372415b01",
  "Zeeland": "e2c506ce-03ca-e211-a270-001372415b01",
  "Flevoland": "c8c506ce-03ca-e211-a270-001372415b01"
};
